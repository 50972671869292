import { TreeViewInstance } from '~/types/view-elements'

const Status = [
  {
    name: 'All',
    value: '',
    icon: 'carbon-list',
  },
  {
    name: 'Draft',
    value: 'draft',
    icon: 'carbon-edit',
  },
  {
    name: 'Published',
    value: 'published',
    icon: 'carbon-checkmark',
  },
]

function getConfigurationNamesAsJoinedString(instance: TreeViewInstance, shortened = false): string {
  if (!instance.system_attributes?.variability_configurations) return ''

  const names = instance.system_attributes.variability_configurations.map(c => c?.variability)?.map(v => v?.name)

  if (shortened) {
    names.forEach((name, index) => {
      if (name) {
        const maxLength = 32
        // Based of the number of names, we can calculate the maximum length of each name
        const nameLength = Math.floor(maxLength / names.length)
        names[index] = name.length > nameLength ? `${name.substring(0, nameLength)}...` : name
      }
    })
  }

  return names.join(', ')
}

// Utility function to build common HTML structure
const buildHtml = (title: string, content: string | number | undefined | null) => `
<div class="flex flex-col gap-0.5 text-gray-900">
  <span class="text-xs">${title}:</span>
  <div class="flex gap-1 text-xs font-semibold">
    ${content}
  </div>
</div>
`

type TranslationFunction = (key: string) => string

// Helper functions for specific log types
const formatFromTo = (
  t: TranslationFunction,
  oldVal: string | number | undefined | null,
  newVal: string | number | undefined | null,
) => `${t('global.from')}: ${oldVal}<br />${t('global.to')}: ${newVal}` as const

const formatEdgeCreated = (t: TranslationFunction, entity: string | undefined, relationship: string | undefined) =>
  `${t('global.to')}: ${entity}<br />${t('global.relationship')}: ${relationship}` as const

const formatAttributeUpdate = (
  t: TranslationFunction,
  attribute: string | undefined,
  oldVal: string | number | undefined,
  newVal: string | number | undefined,
) => `${attribute}<br />${formatFromTo(t, oldVal, newVal)}` as const

export {
  buildHtml,
  formatAttributeUpdate,
  formatEdgeCreated,
  formatFromTo,
  getConfigurationNamesAsJoinedString,
  Status,
}
