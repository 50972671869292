export function useIntersectionObserver(selector: string, cb: () => void, opts: IntersectionObserverInit = {}) {
  const observer = ref<IntersectionObserver | null>(null)

  const supportsObserver = () => {
    return 'IntersectionObserver' in window
  }

  function registerObserver() {
    if (!supportsObserver()) return

    const options = {
      rootMargin: '0px',
      root: null,
      ...opts,
    }

    observer.value = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.intersectionRatio > 0) {
          cb()
        }
      })
    }, options)

    const target = document.querySelector(selector)

    if (target) {
      observer.value.observe(target)
    }
  }

  function unRegisterObserver() {
    if (!supportsObserver()) return

    const target = document.querySelector(selector)
    if (target && observer.value) {
      observer.value.unobserve(target)
      observer.value = null
    }
  }

  return {
    registerObserver,
    unRegisterObserver,
  }
}
