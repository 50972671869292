<script lang="ts" setup>
import { useApiRequest } from '~/composables/useApiRequest'
import { apiStore } from '~/stores/api'
import { entityTypesStore } from '~/stores/entity_types'
import { unwrapApiErrors } from '~/types/api'
import { ToastList } from '~/types/toast'
import { Attribute, CreateSupplier, Entity } from '~/types/view-elements'

const props = defineProps({
  open: {
    type: Boolean,
    required: true,
  },
  defaultType: {
    type: String,
    required: false,
    default: '',
  },
})

const { t } = useI18n()

const emit = defineEmits<{
  close: []
  create: [entity: Entity]
}>()

const state = reactive({
  isLoading: false,
  file: null as File | null,
  toast: inject('toast') as ToastList,
  uploadFilePath: '',
})

const data = reactive({
  description: '',
  name: '',
  status: 'draft',
  attributes: [],
  otherAttributes: [],
  contactAttributes: [],
})

const router = useRouter()

const isSaveDisabled = computed(() => {
  return !data.name || !data.description || state.isLoading
})

function closeModal() {
  emit('close')
}

function resetData() {
  data.name = ''
  data.description = ''
  data.schema_type = ''
  state.file = null
}

type FileHandlingEvent = Event & { target: HTMLInputElement }

function handleFileDrop(event: FileHandlingEvent) {
  const file = event.target.files?.[0]
  if (file) state.file = file
}

function handleFileReset(event: Event) {
  event.preventDefault()
  const input = document.getElementById('add-entity-image') as HTMLInputElement
  input.value = ''
  state.file = null
}

function handleDragOver(event: DragEvent) {
  event.preventDefault()
}

function handleDrop(event: DragEvent) {
  event.preventDefault()

  const file = event.dataTransfer?.files?.[0]
  if (file) {
    state.file = file
    const input = document.getElementById('add-entity-image') as HTMLInputElement
    input.files = event.dataTransfer?.files
  }
}

const uploadRequest = useApiRequest(async api => {
  if (state.file) {
    const uploadedFile = await api.uploadPublicAsset(state.file)
    state.uploadFilePath = uploadedFile.path
  }
})

async function createEntity() {
  state.isLoading = true
  const api = apiStore().getApiClient

  try {
    const payload: CreateSupplier = {
      name: data.name,
      description: data.description,
      attributes: [...data.attributes, ...data.contactAttributes, ...data.otherAttributes],
    }

    if (state.file) {
      if (!state.file.type.startsWith('image/')) {
        state.toast.error(t('global.error'), t('global.invalid_image_error'))
        return
      }

      const result = await uploadRequest.runRequest()
      if (!result) {
        return
      }
      payload.image_url = state.uploadFilePath
      payload.image = state.uploadFilePath
    }

    const result = await api.createSupplier(payload)

    state.toast.success(t('global.success'), t('suppliers.created_success'))
    emit('create', result.data)
  } catch (error) {
    const errorMessage = unwrapApiErrors(error)
    state.toast.error(t('global.error'), errorMessage)
  } finally {
    setTimeout(() => {
      state.isLoading = false
      resetData()
    }, 250)
    closeModal()
  }
}

onBeforeMount(async () => {
  await entityTypesStore().loadEntityTypes()
  const supplierEntity = entityTypesStore().entity_types.list.filter(e => e.name === 'Supplier')
  // If Supplier does not exists we are redirecting user to EntityType tab in knowledge
  if (supplierEntity.length <= 0) {
    router.push('/knowledge?tab=1')
    return
  }

  if (supplierEntity[0].attributes && supplierEntity[0].attributes.length > 0) {
    data.attributes = []
    const addressAttrs = ['street1', 'street2', 'city', 'state', 'country', 'zip']
    const contactAttrs = ['email', 'phone']

    const attrs = supplierEntity[0].attributes.map((attr: Attribute) => {
      attr.value = null
      return attr
    })

    for (let attrSlug of addressAttrs) {
      const foundAttr = attrs.find(attr => attr.slug === attrSlug)
      if (foundAttr) {
        data.attributes.push(foundAttr)
      }
    }

    for (let attrSlug of contactAttrs) {
      const foundAttr = attrs.find(attr => attr.slug === attrSlug)
      if (foundAttr) {
        data.contactAttributes.push(foundAttr)
      }
    }

    for (let attr of attrs) {
      if (!addressAttrs.includes(attr.slug) && !contactAttrs.includes(attr.slug)) {
        data.otherAttributes.push(attr)
      }
    }
  } else {
    data.attributes = []
  }
})
</script>

<template>
  <OModal :open="props.open" @close="closeModal" :maxWidth="1200">
    <template #content>
      <div class="flex flex-col -m-6">
        <div class="flex justify-between gap-2 py-4 px-6">
          <div class="flex items-center gap-2">
            <h2 class="text-xl font-semibold text-gray-900 line-clamp-1">
              {{ $t('suppliers.create_title') }}
            </h2>
          </div>
          <div class="flex items-center gap-3">
            <button class="text-gray-500" @click="emit('close')">
              <div i="carbon-close" class="w-6 h-6" />
            </button>
          </div>
        </div>

        <span class="h-[1px] w-full bg-gray-200" />

        <div class="flex flex-col px-6 pt-4 gap-4 overflow-scroll" style="height: calc(100vh - 20vh)">
          <div class="flex flex-col gap-1">
            <p class="text-sm text-gray-500">{{ $t('global.name') }}</p>
            <FormKit
              type="text"
              v-model="data.name"
              name="name"
              :placeholder="$t('global.name')"
              input-class="!text-sm"
            />
          </div>

          <div class="flex flex-col gap-1">
            <p class="text-sm text-gray-500">{{ $t('global.description') }}</p>
            <FormKit
              type="textarea"
              v-model="data.description"
              name="description"
              :placeholder="$t('global.description')"
              input-class="!text-sm"
            />
          </div>

          <div v-if="!props.defaultType" class="flex flex-col gap-1">
            <p class="text-sm text-gray-500">{{ $t('global.type') }}</p>
            <FormKit
              type="select"
              v-model="data.schema_type"
              :options="entityTypesStore().getEntityTypesAsOptionsWithNameAsValue"
              name="schema_type"
              :placeholder="$t('global.type')"
              input-class="!text-sm"
              suffix-icon="carbon-chevron-down"
            />
          </div>

          <div class="formkit-wrapper">
            <label class="text-sm text-gray-500 text-left">{{ $t('projects.select_image') }}</label>
            <div class="relative flex items-center justify-center w-full">
              <label
                for="add-entity-image"
                class="group flex flex-col items-center justify-center w-full h-32 border border-dashed border-gray-300 bg-white hover:bg-gray-50 cursor-pointer rounded transition"
                @dragover="handleDragOver"
                @drop="handleDrop"
              >
                <div>
                  <div class="flex flex-col items-center justify-center w-full h-full pt-1 pb-2">
                    <div i="carbon-cloud-upload" class="w-8 h-8 mb-2 text-gray-500" />
                    <p class="mb-2 text-sm text-gray-500">
                      <span class="font-semibold">{{ `${$t('file_upload.click_cta')}` }}</span>
                      <span>{{ ` ${$t('file_upload.click_or_drag_cta')}.` }}</span>
                    </p>
                    <p v-if="!state.file" class="text-xs text-gray-500">
                      {{ $t('global.upload') }} {{ $t('global.file') }}
                    </p>
                    <div v-else class="flex gap-1 text-sm text-gray-500">
                      {{ state.file.name }}
                      <button class="flex justify-center items-center mt-0.5" @click.stop.prevent="handleFileReset">
                        <div i="carbon-close" class="w-4 h-4 text-gray-500" />
                      </button>
                    </div>
                  </div>

                  <input id="add-entity-image" type="file" class="hidden" @input="handleFileDrop" />
                </div>
              </label>
            </div>
          </div>
          <div class="mb-6">
            <AttributesForm
              v-model="data.attributes"
              attributeClass="col-span-2 lg:col-span-1"
              wrapper-class="grid grid-cols-2 gap-6"
            >
              <template #header>
                <div class="flex items-center gap-2 mb-1 font-semibold text-gray-800">
                  <div i="carbon-home" />
                  <h3>{{ t('global.address') }}</h3>
                </div>
              </template>
            </AttributesForm>

            <AttributesForm
              v-model="data.contactAttributes"
              attributeClass="col-span-2 lg:col-span-1"
              wrapper-class="grid grid-cols-2 gap-6"
            >
              <template #header>
                <div class="flex items-center gap-2 mb-1 font-semibold text-gray-800">
                  <div i="carbon-phone" />
                  <h3>{{ t('global.contact') }}</h3>
                </div>
              </template>
            </AttributesForm>

            <AttributesForm
              v-model="data.otherAttributes"
              attributeClass="col-span-2 lg:col-span-1"
              wrapper-class="grid grid-cols-2 gap-6"
            >
              <template #header>
                <div class="flex items-center gap-2 mb-1 font-semibold text-gray-800">
                  <div i="carbon-list-bulleted" />
                  <h3>{{ t('global.other') }}</h3>
                </div>
              </template>
            </AttributesForm>
          </div>
        </div>
      </div>
    </template>

    <template #footer>
      <div class="flex justify-end w-full gap-4">
        <button class="btn-secondary" @click="closeModal">
          {{ $t('global.cancel') }}
        </button>
        <button class="btn-primary" @click="createEntity" :disabled="isSaveDisabled">
          {{ state.isLoading ? $t('global.creating') : $t('global.create') }}
        </button>
      </div>
    </template>
  </OModal>
</template>
