import Anthropic from '@anthropic-ai/sdk'
import { z } from 'zod'

export function isTextContentDelta(
  delta: Anthropic.TextDelta | Anthropic.InputJSONDelta,
): delta is Anthropic.TextDelta {
  return delta.type === 'text_delta'
}

export function isJsonInputDelta(
  delta: Anthropic.TextDelta | Anthropic.InputJSONDelta,
): delta is Anthropic.InputJSONDelta {
  return delta.type === 'input_json_delta'
}

export function maybeParseJson(someString: string, schema?: z.ZodType) {
  const parser = schema?.parse ?? (x => x)

  try {
    return parser(JSON.parse(someString))
  } catch {
    return someString
  }
}
