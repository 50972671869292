<script setup lang="ts">
// When Parent is Catalog
// Show Generic
// Else show XLS | JSON

import { useApiRequest } from '~/composables/useApiRequest'
import { ETLConfig, ETLTypes, ETLTypesConfig } from '~/config/etl'
import { getCurrentTenant } from '~/config/tenants'
import { growthBookKey } from '~/modules/growthbook'
import { apiStore } from '~/stores/api'
import type { ToastList } from '~/types/toast'

const growthBookInjectable = inject(growthBookKey)

const props = defineProps({
  open: {
    type: Boolean,
    required: true,
  },
  importFrom: {
    type: String,
    required: true,
    value: 'catalog',
  },
})

const emit = defineEmits<{
  close: []
  'import-success': []
}>()

let ETLType: ETLConfig[] = Object.values(ETLTypesConfig).filter(type => typeof type.tenant === 'undefined')

const { t } = useI18n()

const signed_url = ref('')

const ETLTypeAsOptions = computed(() => {
  return ETLType.map(type => ({
    label: t(`entities.import_${type.key}`),
    value: type.key,
  }))
})

const state = reactive({
  toast: inject('toast') as ToastList,
  isImportLoading: false,
  file: null as File | null,
  import_type: ETLTypes.GENERIC_BOM,
  message: '',
  dragOverActive: false,
})

const selectedETLType = computed(() => {
  return ETLType.find(type => type.key === state.import_type)
})

function setDragHoverActive(_: any) {
  state.dragOverActive = true
}

function setDragHoverInactive(_: any) {
  state.dragOverActive = false
}

function closeModal() {
  emit('close')
}

function handleFileSubmit(event: any) {
  let file: File | null = null

  if (event.type === 'drop') {
    file = event.dataTransfer.files[0]
    state.dragOverActive = false
  } else {
    file = event.target.files[0]
  }

  if (file) {
    setFileIfMatchingType(file)
  }
}

function validateFileExtension(file: File): boolean {
  const allowedExtensions = selectedETLType.value?.allowed_extensions ?? ['xlsx', 'xls', 'json', 'csv']
  const fileExtension = file.name.split('.').pop()

  return allowedExtensions.includes(fileExtension || '')
}

function setFileIfMatchingType(file: File) {
  if (!validateFileExtension(file)) {
    state.toast.error(t('global.error'), t('global.file_extension_not_allowed'))
    return
  }

  state.file = file
}

function handleFileReset() {
  const input = document.getElementById('graph-import-dropzone-file') as HTMLInputElement
  input.value = ''
  state.file = null
}

async function startFileImport() {
  if (!state.file) return

  state.isImportLoading = true

  try {
    await uploadSubmittedFile(state.file)
  } catch (error: any) {
    state.toast.error(t('global.error'), error.response?.data?.message || error.toString())
  }

  state.isImportLoading = false

  setTimeout(() => {
    closeModal()
  }, 300)
}

const uploadRequest = useApiRequest(async api => {
  if (state.file) {
    const response = await api.uploadFile(state.file)
    signed_url.value = response.data.url
  }
}
)

async function uploadSubmittedFile(file: File) {
  const api = apiStore().getApiClient
  const fileName = `${state.import_type}-${file.name.split('.')[0]}_${Date.now()}.${file.name.split('.').pop()}`
  const fileExtension = fileName.split('.').pop()
  const allowedExtensions = ['xlsx', 'xls', 'json', 'csv']

  if (!fileExtension || !allowedExtensions.includes(fileExtension)) {
    state.toast.error(t('global.error'), t('global.file_extension_not_allowed'))
    return
  }

  state.message = t('global.loading_file')

  const response = await uploadRequest.runRequest()
  if (!response) {
    return
  }

  if (signed_url.value) {
    state.message = t('global.parsing_file')

    try {
      await api.importETL({
        type: selectedETLType?.value?.payload_type ?? 'generic',
        url: signed_url.value,
      })
      state.toast.success(t('global.success'), t('entities.import_upload_success'))

      emit('import-success')
    } catch (error) {
      state.toast.error(t('global.error'), t('global.file_upload_failed') + ': ' + error)
    }
  } else {
    state.toast.error(t('global.error'), t('global.file_upload_failed') + ': ' + JSON.stringify(response.error))
  }
}

onBeforeMount(async () => {
  if (props.importFrom === 'catalog') {
    ETLType = Object.values(ETLTypesConfig).filter(type => type.importType === 'catalog')
  } else {
    const growthBook = await growthBookInjectable?.init()
    const currentTenant = await getCurrentTenant(growthBook)

    ETLType = Object.values(ETLTypesConfig).filter(type => {
      if (type.importType !== 'bom') return false

      // if current tenant is default, we show all ETL options
      // which are not associated with a tenant
      // otherwise we only show the ETL options for this tenant
      return !type.tenant || type.tenant === currentTenant
    })
    state.import_type = ETLType[0].key
  }
})
</script>

<template>
  <OModal :open="props.open" @close="closeModal">
    <template #content>
      <div
        class="flex flex-col gap-6"
        @dragenter.prevent="setDragHoverActive"
        @dragover.prevent="setDragHoverActive"
        @dragleave.prevent="setDragHoverInactive"
        @drop.prevent="handleFileSubmit"
      >
        <FormKit
          v-model="state.import_type"
          :key="state.import_type"
          type="select"
          :options="ETLTypeAsOptions"
          :label="$t('entities.import_type')"
          label-class="font-semibold"
          suffix-icon="carbon-chevron-down"
        />
        <label class="formkit-label block mb-4 text-gray-700 text-left font-semibold">{{
          $t('entities.import_select_file')
        }}</label>
        <div class="relative flex items-center justify-center w-full">
          <label
            for="graph-import-dropzone-file"
            class="group flex flex-col items-center justify-center w-full h-32 border border-dashed bg-white hover:bg-gray-50 cursor-pointer rounded transition"
            :class="state.dragOverActive ? 'border-blue-500' : 'border-gray-300'"
          >
            <template v-if="!state.isImportLoading">
              <div class="absolute flex flex-col items-center justify-center w-full h-full pt-1 pb-2">
                <div i="carbon-cloud-upload" class="w-8 h-8 mb-2 text-gray-500" />
                <p class="mb-2 text-sm text-gray-500">
                  <span class="font-semibold">{{ `${$t('file_upload.click_cta')}` }}</span>
                  <!-- <span>{{ `, ${$t('file_upload.click_or_drag_cta')}.` }}</span> -->
                </p>
                <p v-if="!state.file" class="text-xs text-gray-500">
                  {{ `${selectedETLType?.fileType} ${$t('global.file')}` }}
                </p>
                <div v-else class="flex gap-1 text-sm text-gray-500">
                  {{ state.file.name }}
                  <button class="flex justify-center items-center mt-0.5" @click.stop.prevent="handleFileReset">
                    <div i="carbon-close" class="w-4 h-4 text-gray-500" />
                  </button>
                </div>
              </div>
              <input
                id="graph-import-dropzone-file"
                type="file"
                class="hidden"
                :accept="
                  selectedETLType?.allowed_extensions.map(ext => `.${ext}`).join(', ') ?? '.xlsx, .xls, .json, .csv'
                "
                @input="handleFileSubmit"
              />
            </template>
            <div v-else>
              <Loader />
              <div class="text-sm text-gray-500">{{ state.message }}</div>
            </div>
          </label>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="flex justify-end w-full gap-4">
        <button class="btn-secondary w-full" @click="closeModal">
          {{ $t('global.cancel') }}
        </button>
        <button class="btn-primary w-full" :disabled="!state.file || state.isImportLoading" @click="startFileImport">
          {{ $t('global.apply') }}
        </button>
      </div>
    </template>
  </OModal>
</template>
