export function isValidURL(str?: string) {
  if (!str) return false
  // Regular expression pattern for URL validation
  const urlPattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol (optional)
      '((([a-zA-Z\\d]([a-zA-Z\\d-]*[a-zA-Z\\d])*)\\.)+[a-zA-Z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-zA-Z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-zA-Z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-zA-Z\\d_]*)?$', // fragment locator
  )
  try {
    return urlPattern.test(assumeProtocol(str) ?? '') || new URL(str).protocol === 'data:'
  } catch {
    return false
  }
}

export function assumeProtocol(str?: string) {
  if (!str) return str
  return str.includes('://') ? str : `https://${str}`
}

export function stripCommas(str?: string) {
  if (!str) return str
  return str.replace(/,/g, '')
}
