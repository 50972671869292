export * from './clients/anthropic'
export * from './clients/openai'
export * from './interface'

import { i18n } from '~/modules/i18n'
import { AnthropicClient } from './clients/anthropic'
import { OpenAIClient } from './clients/openai'
import { AIClient, AICompletionOptions, AICompletionResponse } from './interface'
import { bomImportDataSchema } from './schemas/bomImportData'
import { selectBomsSchema } from './schemas/selectBoms'

export class AI {
  private static clients: Map<string, AIClient> = new Map()

  private static initializeClient(provider: string): AIClient {
    let client: AIClient

    switch (provider.toLowerCase()) {
      case 'openai':
        client = new OpenAIClient()
        break
      case 'anthropic':
        client = new AnthropicClient()
        break
      default:
        throw new Error(`Unsupported AI provider: ${provider}`)
    }

    this.clients.set(provider, client)
    return client
  }

  private static getClient(provider: string): AIClient {
    const normalizedProvider = provider.toLowerCase()
    let client = this.clients.get(normalizedProvider)

    if (!client) {
      client = this.initializeClient(normalizedProvider)
    }

    return client
  }

  static async getCompletion(provider: string, options: AICompletionOptions): Promise<AICompletionResponse> {
    const normalizedProvider = provider.toLowerCase()
    if (!this.getAvailableProviders().includes(normalizedProvider)) {
      throw new Error(`Unsupported AI provider: ${provider}`)
    }
    return this.getClient(normalizedProvider).getCompletion(options)
  }

  static getAvailableProviders(): string[] {
    return ['openai', 'anthropic']
  }

  static getAvailableModels(provider: string): AIClient['models'] {
    return this.getClient(provider).models
  }

  /**
   * Uses AI to help find BOMs that could be imported as children of the current BOM node
   * @param currentBom The current BOM node where we want to add children
   * @param options Optional settings for the AI completion
   * @returns An async generator that yields stream chunks from the AI
   */
  static async *findBomsToImport(
    currentBom: { bomId: string; name: string; description?: string; viewId: string; currentNodeid: string },
    options: Partial<AICompletionOptions> = {},
  ) {
    const locale = i18n.global.locale.value
    const provider: AIClient['provider'] = 'anthropic'
    const model = 'claude-3-5-sonnet-20241022'
    // const provider: AIClient['provider'] = 'openai'
    // const model = 'gpt-4o-2024-08-06'
    const systemPrompt = `You are a helpful assistant that helps users find relevant BOMs (Bills of Materials) to import as children of their current BOM.
Your task is to:
1. Understand the current BOM's purpose and components needed
2. Use the findBom tool to search for potential BOMs to import
3. Analyze the search results and recommend which BOMs would make sense as children
4. For each recommended BOM, explain why it would be appropriate as a child component
5. you must add double lines breaks (\n\n) in your responses to improve readability
6. Explain your reasoning, but without mentioning explicitly the tools you use.
7. Never provide more than 4 recommendations
8. You must end your recommendation by saying "Here are my recommendations:".`

    const outputPrompts: Record<AIClient['provider'], string> = {
      openai: `Provide your recommendations in the required JSON format, including a clear reason for each suggestion.
If you can't find any relevant BOMs, respond with an empty recommendations array.`,
      anthropic: `In order to provide your recommendations, use the selectBoms tool to select a couple of relevant BOMs.
  If you can't find any relevant BOMs, call the tool with an empty recommendations array.`,
    }

    const userPrompt = `I have a BOM with id "${currentBom.bomId}" named "${currentBom.name}"${
      currentBom.description ? ` described as: "${currentBom.description}"` : ''
    }. Its view id is "${currentBom.viewId}".

    Please help me find relevant BOMs that I could import as children of the current node with id "${currentBom.currentNodeid}".
    ${options.userPrompt ?? ''}
    You can check what this node represents using the getBomContent tool.
Use the findBom tool to get the name and descriptions of all the available BOMs.
Use the getBomContent tool to get the content of a the current BOM, with the provided id above. If the current BOM already has a similar element, you don't need to add it again.
Finally, please recommend which boms it would make sense to import into the current bom from the ones returned by the findBom tool, using the requested structured output.
Your response should be in this locale: ${locale}

${outputPrompts[provider] ?? ''}
`

    // console.log(`Running findBomsToImport with: ${provider} - ${model}`)

    const client = this.getClient(provider)
    const stream = client.getStream({
      model,
      systemPrompt,
      temperature: options.temperature ?? 0.7,
      maxTokens: options.maxTokens ?? 1024,
      schema: selectBomsSchema,
      ...options,
      userPrompt,
    })

    for await (const chunk of stream) {
      yield chunk
    }
  }

  static async *createBomCSV(options: Partial<AICompletionOptions> = {}) {
    // const provider: AIClient['provider'] = 'anthropic'
    // const model = 'claude-3-5-sonnet-20241022'

    const provider: AIClient['provider'] = 'openai'
    const model = 'gpt-4o-2024-08-06'

    // console.log(`Running createBomCSV with: ${provider} - ${model}`)

    const systemPrompt = `You are a helpful assistant that helps users create a CSV file from the provided BOM data.
  Your task is to Create a new and original detailed Bill of Materials (BOM) in JSON format with the following specifications:

  your answer should be in the language of the user prompt, or in this locale: ${i18n.global.locale.value}, even for each node's name, description and reason.
  the type should remain one of part, assembly, or raw_material, no matter what the user input or the locale is.
  
  Goal: Generate a comprehensive, hierarchical Bill of Materials for the product or system prompted by the user
  mage
  BOM Formatting Requirements:
  - Use a structured JSON array with these mandatory properties for each item:
    1. level: Hierarchical depth (0 = top-level item)
    2. reference: Unique alphanumeric identifier 
    3. name: Descriptive name of component
    4. type: Classification (choose from: Part, Assembly, RawMaterial)
    5. description: Detailed explanation of the component
    6. uom: always set to 'piece' and never provide a different unit of measure
    7. quantity: Numerical amount required
  
  Detailed Instructions:
  1. Start with the top-level complete product/system (level 0). This first row should have an empty string as the type property
  2. Break down into major assemblies and components (levels 1-3)
  3. Include both functional components and raw materials
  4. Provide realistic quantities
  5. Use a consistent naming and referencing convention
     - Prefix reference codes with a product-specific abbreviation
     - Ensure each reference is unique
  6. Aim for comprehensive coverage without unnecessary granularity
  
  Specific Guidance:
  - Focus on the most critical and representative components
  - Balance detail with readability
  - Consider manufacturing, assembly, and functional perspectives
  - Include both essential and optional components
  - NEVER PUT A COMMA IN ANY OF THE ABOVE PROPERTIES
  
  Example Reference Prefixes:
  - Electronics: ELEC-
  - Automotive: AUTO-
  - Furniture: FURN-
  - Mechanical: MECH-
  `
    const outputPrompts: Record<AIClient['provider'], string> = {
      openai: `Provide your recommendations in the required JSON format, including a clear reason for each suggestion.
  If you can't find any relevant BOMs, respond with an empty recommendations array.`,
      anthropic: `You need to create a new BOM, not find an existing one.
      In order to provide your recommendation's output, you must use the createBomCSV tool to format the new BOM nodes, with the required input properties. you don't need to use other tools.`,
    }

    const userPrompt = `
  ${options.userPrompt}
  ${outputPrompts[provider]}
  `

    const client = this.getClient(provider)
    const stream = client.getStream({
      model,
      systemPrompt,
      userPrompt,
      temperature: options.temperature ?? 0.7,
      maxTokens: options.maxTokens ?? 4096,
      schema: bomImportDataSchema,
      ...options,
    })

    for await (const chunk of stream) {
      yield chunk
    }
  }
}
