import ApiHelper from '~/helpers/ApiHelper'

export const findBomSchema = {
  type: 'object' as const,
  properties: {},
}

export const findBomName = 'findBom'
export const findBomDescription = 'get all BOMs name and description'

export async function findBomShared() {
  const api = new ApiHelper()

  const { data } = await api.getBoms(1, 100, { name: '', status: '' })
  return JSON.stringify(
    data.map(bom => ({
      id: bom.id,
      name: bom.name,
      description: bom.description,
      reference: bom.reference,
      status: bom.status,
    })),
  )
}
