import { findBomDescription, findBomName, findBomSchema, findBomShared } from '../../sharedTools/findBom'
import { AnthropicTool } from '../types'

export const findBomTool: AnthropicTool<object> = {
  name: findBomName,
  description: findBomDescription,
  inputSchema: findBomSchema,
  func: async () => {
    return await findBomShared()
  },
}
