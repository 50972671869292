<script setup lang="ts">
import tippy from 'tippy.js'
import { BomReadiness } from '~/types/view-elements'

const props = defineProps<{
  id: string
  progress: BomReadiness[]
}>()

const totalInstances = computed(() => {
  return props.progress.reduce((sum, item) => sum + item.total_instances, 0)
})

const totalDone = computed(() => {
  return props.progress.reduce((sum, item) => sum + item.statuses.done, 0)
})

const progress = computed(() => {
  const total = totalInstances.value
  const done = totalDone.value
  return total === 0 ? 0 : Number(((done / total) * 100).toFixed(2))
})

const progressByView = computed(() => {
  return props.progress.map(item => {
    const total = item.total_instances ?? 0
    const done = item.statuses.done ?? 0
    return {
      name: item.view.name,
      value: total === 0 ? 0 : Number(((done / total) * 100).toFixed(2)),
    }
  })
})

onMounted(() => {
  const template = document.getElementById(`tooltip-content-${props.id}`)
  tippy(`#bom-readiness-${props.id}`, {
    content: template?.innerHTML,
    allowHTML: true,
  })
})
</script>
<template>
  <div :id="`tooltip-content-${id}`" class="hidden">
    <div class="text-center">
      <div v-for="item in progressByView" :key="item.name">
        {{ item.name }}: <strong>{{ item.value }}%</strong>
      </div>
    </div>
  </div>

  <div :id="`bom-readiness-${id}`" class="bg-gray-50 rounded w-full flex items-center justify-center py-1 px-2 gap-2">
    <div class="relative w-full h-1 bg-blue-100 rounded">
      <div class="absolute bg-blue-500 h-full rounded" :style="{ width: `${progress}%` }" />
    </div>
    <CText size="xs" class="!text-gray-600 line-clamp-1 flex-shrink-0"> {{ progress }}% </CText>
  </div>
</template>
