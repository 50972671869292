import ApiHelper from '~/helpers/ApiHelper'

export interface GetBomContentInput {
  id: string
  viewId: string
}

export const getBomContentSchema = {
  type: 'object' as const,
  properties: {
    id: {
      type: 'string',
    },
    viewId: {
      type: 'string',
    },
  },
  required: ['id', 'viewId'],
}

export const getBomContentName = 'getBomContent'

export const getBomContentDescription =
  'returns the name, id, description of the current BOM, and the list of all the nodes, with their name, description and type'

export async function getBomContentShared(args: GetBomContentInput) {
  const api = new ApiHelper()
  const [bom, tree] = await Promise.all([api.getBom(args.id), api.getBomAsTree(args.id, args.viewId)])

  return JSON.stringify({
    id: bom.data.id,
    name: bom.data.name,
    description: bom.data.description,
    ref: bom.data.reference,
    nodes: tree.nodes.map(node => ({
      id: node.id,
      name: node.label,
      description: node.description,
      type: node.group,
    })),
  })
}
