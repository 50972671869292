<script setup lang="ts">
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { reactive } from 'vue'

type Option = {
  name: string
  value: string
  icon: string
}

const props = defineProps({
  options: Array<Option>,
  value: String,
})

onBeforeMount(() => {
  const status = props.options?.find(opt => opt.value === state.value)?.value || ''
  if (!status) {
    state.value = ''
    emit('update:modelValue', '')
  }
})

const state = reactive({
  value: props.value,
})

const route = useRoute()
const router = useRouter()

let emit = defineEmits(['update:modelValue'])

function statusChanged(value: string) {
  state.value = value
  const query = { ...route.query }
  query.status = state.value
  router.replace({ name: String(route.name), params: route.params, query: query })
  emit('update:modelValue', value)
}
</script>

<template>
  <Menu as="div" class="relative inline-block text-left w-full">
    <MenuButton class="btn-secondary gap-1.5 h-full !hover:text-secondary">
      <div i="carbon-filter" />
      <span>{{ $t('global.filter_by_status') }}</span>
      <PublishingStatusTag v-if="state.value" :status="state.value" class="ml-2" />
    </MenuButton>

    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <MenuItems class="origin-top-right absolute left-0 mt-2 w-56 z-50 rounded shadow-lg bg-white">
        <div class="py-1">
          <MenuItem v-slot="{ active }" v-for="option in props.options" :key="option.value">
            <div
              @click="statusChanged(option.value)"
              class="pl-4"
              :class="[
                active || state.value === option.value ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                'cursor-pointer flex items-center px-4 py-2 text-sm',
              ]"
            >
              <div :i="option.icon" v-if="option.icon" />
              <a :class="option.icon ? 'ml-4' : 'ml-0'">{{ option.name }}</a>
            </div>
          </MenuItem>
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>
