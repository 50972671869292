import Anthropic from '@anthropic-ai/sdk'
import { AnthropicTool } from '../types'
import { createBomCSVTool } from './createBomCSV'
import { findBomTool } from './findBom'
import { getBomContentTool } from './getBomContent'
import { selectBomsTool } from './selectBoms'

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export const tools: AnthropicTool<any>[] = [findBomTool, selectBomsTool, getBomContentTool, createBomCSVTool]

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
const toolsMap = new Map<string, AnthropicTool<any>>(tools.map(tool => [tool.name, tool]))

export function getToolsForPrompt(): Anthropic.Tool[] {
  return tools.map(tool => ({
    name: tool.name,
    description: tool.description,
    input_schema: tool.inputSchema,
  }))
}

export async function invokeTool<T>(name: string, input: T): Promise<string> {
  const tool = toolsMap.get(name) as AnthropicTool<T> | undefined
  if (!tool) {
    throw new Error(`Tool ${name} not found`)
  }
  return tool.func(input)
}
