import { getEnv } from '~/config'
import ApiHelper from '~/helpers/ApiHelper'
import { apiStore } from '~/stores/api'
import { useToasts } from '~/stores/toasts'
import { unwrapApiErrors } from '~/types/api'

interface UseApiRequestOptions {
  onError?: (errorMessage: string) => void
}

export function useApiRequest(doRequest: (api: ApiHelper) => void, options: UseApiRequestOptions = {}) {
  const api = apiStore().getApiClient
  const toast = useToasts()
  const { t } = useI18n()
  const isLoading = ref(false)
  const hasFailed = ref(false)

  async function runRequest() {
    try {
      isLoading.value = true
      hasFailed.value = false
      await doRequest(api)
      return true
    } catch (error) {
      if (getEnv() === 'local') {
        // eslint-disable-next-line no-console
        console.error(error)
      }

      const errorMessage = unwrapApiErrors(error)

      toast?.error(t('global.error'), errorMessage)
      hasFailed.value = true

      if (options.onError) {
        options.onError(errorMessage)
      }
      return false
    } finally {
      isLoading.value = false
    }
  }

  return {
    runRequest,
    isLoading,
    hasFailed,
  }
}
