import { z } from 'zod'

export const bomImportDataSchema = z.object({
  nodes: z
    .array(
      z.object({
        level: z.number().describe('Level of the node in the BOM'),
        reference: z.string().describe('Reference of the node'),
        name: z.string().describe('Name of the node'),
        type: z.enum(['Part', 'Assembly', 'RawMaterial']).describe('Type of the node'),
        description: z.string().describe('Description of the node'),
        uom: z.enum(['piece']).describe('Unit of measure of the node'),
        quantity: z.number().describe('Quantity of the node'),
      }),
    )
    .describe('List of BOMs to import'),
})
