export function useDialog(initialState = false) {
  const isOpen = ref<boolean>(initialState)

  function open() {
    isOpen.value = true
  }

  function close() {
    isOpen.value = false
  }

  return {
    isOpen,
    open,
    close,
  }
}
