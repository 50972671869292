import { AnthropicTool } from '../types'

interface SelectBomsInput {
  boms: {
    id: string
    name: string
    description: string
    status: string
    reason: string
    reference: string
  }[]
}

export const selectBomsTool: AnthropicTool<SelectBomsInput> = {
  name: 'selectBoms',
  description: 'Select a couple relevant BOMs',
  inputSchema: {
    type: 'object',
    properties: {
      boms: {
        type: 'array',
        items: {
          type: 'object',
          properties: {
            id: {
              type: 'string',
            },
            name: {
              type: 'string',
            },
            description: {
              type: 'string',
            },
            status: {
              type: 'string',
            },
            reference: {
              type: 'string',
            },
            reason: {
              type: 'string',
            },
          },
          required: ['id', 'name', 'description', 'status', 'reason', 'reference'],
        },
      },
    },
    required: ['boms'],
  },
  func: async (input: SelectBomsInput) => {
    return JSON.stringify(input.boms)
  },
}
