interface SortProperty {
  key: string
  defaultOrder: 'asc' | 'desc'
  queryParam: string
}

export function useSort(
  properties: SortProperty[],
  onSort: () => void,
  initialValues: { order: 'asc' | 'desc' | null; key: string } = {
    order: null,
    key: '',
  },
) {
  type ValidKeys = (typeof properties)[number]['key']
  const orderRef = ref<'asc' | 'desc' | null>(initialValues.order)
  const keyRef = ref<ValidKeys>(initialValues.key)

  async function setSort(key: ValidKeys) {
    if (keyRef.value === key) {
      // Get the current property's default order
      const property = properties.find(p => p.key === key)!
      const defaultOrder = property.defaultOrder

      // Cycle through orders: defaultOrder -> opposite -> null
      if (orderRef.value === null) {
        // If no current order, start with default
        orderRef.value = defaultOrder
      } else if (orderRef.value === defaultOrder) {
        // If at default, go to opposite
        orderRef.value = defaultOrder === 'asc' ? 'desc' : 'asc'
      } else {
        // If at opposite, go to null
        orderRef.value = null
      }
    } else {
      keyRef.value = key
      orderRef.value = properties.find(p => p.key === key)!.defaultOrder
    }

    await nextTick()
    onSort()
  }

  const sortQueryParam = computed(() => {
    if (orderRef.value === null) return

    const property = properties.find(p => p.key === keyRef.value)

    return orderRef.value === 'asc' ? `${property!.queryParam}` : `-${property!.queryParam}`
  })

  return {
    order: orderRef,
    sortKey: keyRef,
    setSort,
    sortQueryParam,
  }
}
