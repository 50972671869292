import { defineStore } from 'pinia'
import { Toast } from '~/types/toast'
import { v4 as uuid } from 'uuid'

interface State {
  list: Toast[]
}

export const useToasts = defineStore({
  id: 'toasts',
  state: (): State => ({
    list: [],
  }),
  actions: {
    success: function (title: string, message: string, displayInterval = 8000) {
      this.list.push({ success: true, title, message, displayInterval, id: uuid(), info: false })
    },
    error: function (title: string, message: string, displayInterval = 8000) {
      this.list.push({ success: false, title, message, displayInterval, id: uuid(), info: false })
    },
    info: function (title: string, message: string, displayInterval = 8000) {
      this.list.push({ success: false, title, message, displayInterval, id: uuid(), info: true })
    },

    remove: function (id: string) {
      this.list = this.list.filter(toast => toast.id !== id)
    },
  },
})
