import { z } from 'zod'

export const selectBomsSchema = z.object({
  boms: z
    .array(
      z.object({
        id: z.string().describe('Unique identifier of the BOM'),
        name: z.string().describe('Name of the BOM'),
        description: z.string().optional().describe('Optional description of the BOM'),
        status: z.string().describe('Status of the BOM'),
        reference: z.string().describe('Reference of the BOM'),
        reason: z.string().describe('Explanation of why this BOM is relevant as a child component'),
      }),
    )
    .describe('List of recommended BOMs to import as children'),
})

export type AINodeSuggestion = z.infer<typeof selectBomsSchema>['boms'][number]
