import Echo from 'laravel-echo'
import { defineStore } from 'pinia'
import { i18n } from '~/modules/i18n'
import { apiStore } from './api'
import { useSockets } from './sockets'
import { useToasts } from './toasts'
import { userStore } from './user'

export interface Notification {
  id: string
  type: string
  data: { message: string; url?: string }
  created_at: string
  read_at: string
}

type NotificationListener = (notification: Notification) => void

interface State {
  notifications: Notification[]
  isReachedAtEnd: boolean
  total: number
  socket: Echo | null
  listeners: NotificationListener[]
}

export const notificationsStore = defineStore({
  id: 'notification',
  state: (): State => ({
    notifications: [],
    isReachedAtEnd: false,
    total: 0,
    socket: null,
    listeners: [],
  }),
  getters: {
    getSocketClient: async (state): Promise<Echo> => {
      if (state.socket) return state.socket

      const sockets = useSockets()
      state.socket = await sockets.getClient()
      return state.socket
    },
  },
  actions: {
    async loadNotications(perPage: number, currentPage: number) {
      if (this.isReachedAtEnd) {
        return
      }

      const api = apiStore().getApiClient
      const data = await api.getUnreadNotifications(perPage, currentPage)
      this.total = data.meta.total
      if (data.data.length <= 0) {
        this.isReachedAtEnd = true
      } else {
        data.data.forEach((notification: Notification) => {
          this.push(notification)
        })
      }
    },

    async markAsRead(id: string) {
      const api = apiStore().getApiClient
      const idx = this.notifications.findIndex(notification => notification.id === id)
      if (idx !== -1) {
        this.notifications.splice(idx, 1)
        this.total -= 1
        return await api.readNotification(id)
      }
    },

    get(id: string) {
      const idx = this.notifications.findIndex(notification => notification.id === id)
      if (idx === -1) {
        return -1
      }

      return this.notifications[idx]
    },

    // enqueue will add notification at first position
    enqueue(notification: Notification) {
      const idx = this.get(notification.id)
      if (idx === -1) {
        this.notifications.unshift(notification)
        this.total += 1
      }
    },

    // push will append notification at last position
    push(notification: Notification) {
      const idx = this.get(notification.id)
      if (idx === -1) {
        this.notifications.push(notification)
      }
    },

    async readAll() {
      const api = apiStore().getApiClient
      await api.readAllNotifications()
      this.notifications = []
      this.total = 0
    },

    addListener(listener: NotificationListener) {
      this.listeners.push(listener)
    },

    removeListener(listener: NotificationListener) {
      const idx = this.listeners.indexOf(listener)
      if (idx !== -1) {
        this.listeners.splice(idx, 1)
      }
    },

    async subscribe() {
      const client = await this.getSocketClient
      const toasts = useToasts()

      client
        .private(`Notifications.${userStore()?.user?.id}`)
        .listen('.notification.created', (notification: Notification) => {
          toasts.info(i18n.global.t('global.info'), notification.data.message)
          notificationsStore().enqueue(notification)
          this.listeners.forEach(listener => {
            listener(notification)
          })
        })
    },

    async unsubscribe() {
      this.listeners = []
      const client = await this.getSocketClient
      client.leave(`Notifications.${userStore()?.user?.id}`)
    },
  },
})
