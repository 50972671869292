import Anthropic from '@anthropic-ai/sdk'
import { BaseAIClient } from '../../baseClient'
import { AIClient, AICompletionOptions, AICompletionResponse, AIModel, AIStreamChunk } from '../../interface'
import { isJsonInputDelta, isTextContentDelta, maybeParseJson } from './helpers'
import { getToolsForPrompt, invokeTool } from './tools'

const SHOULD_YIELD_TOOLS = ['selectBoms', 'createBomCSV']

export class AnthropicClient implements AIClient {
  private client: BaseAIClient<
    Anthropic.MessageCreateParams,
    Anthropic.Messages.RawMessageStreamEvent | { type: 'error'; content: string },
    Anthropic.Message
  >
  readonly provider = 'anthropic'
  readonly models: AIModel[] = [
    {
      id: 'claude-3-opus-20240229',
      name: 'Claude 3 Opus',
      contextWindow: 200000,
      supportsJson: true,
    },
    {
      id: 'claude-3-sonnet-20240229',
      name: 'Claude 3 Sonnet',
      contextWindow: 200000,
      supportsJson: true,
    },
    {
      id: 'claude-2.1',
      name: 'Claude 2.1',
      contextWindow: 200000,
      supportsJson: true,
    },
  ]

  constructor() {
    this.client = new BaseAIClient<
      Anthropic.MessageCreateParams,
      Anthropic.Messages.RawMessageStreamEvent | { type: 'error'; content: string },
      Anthropic.Message
    >({
      url: '/api/anthropic/messages',
    })
  }

  getAvailableModels(): AIModel[] {
    return this.models
  }

  async getCompletion(
    options: AICompletionOptions & { message?: Anthropic.MessageCreateParams['messages'] },
  ): Promise<AICompletionResponse> {
    if (options.stream) {
      const chunks: AIStreamChunk[] = []
      for await (const chunk of this.getStream(options)) {
        chunks.push(chunk)
      }

      // Combine all text chunks
      const textContent = chunks
        .filter(chunk => chunk.type === 'text')
        .map(chunk => chunk.content)
        .join('')

      return {
        content: textContent,
        model: options.model,
        provider: this.provider,
        // Note: Usage information not available in streaming mode
        usage: {
          promptTokens: 0,
          completionTokens: 0,
          totalTokens: 0,
        },
      }
    }

    const chatOptions: Anthropic.MessageCreateParams = {
      model: options.model,
      system: options.systemPrompt,
      messages: options.message ?? [{ role: 'user', content: options.userPrompt }],
      temperature: options.temperature ?? 0.7,
      max_tokens: options.maxTokens ?? 1024,
      tools: getToolsForPrompt(),
    }

    const response = await this.client.getCompletion(chatOptions)
    const [content] = response.content

    if (content.type === 'tool_use') {
      return this.replyWithToolResult(options, content)
    }

    return {
      content: maybeParseJson(content.text, options.schema),
      model: response.model,
      provider: this.provider,
      usage: {
        promptTokens: response.usage?.input_tokens || 0,
        completionTokens: response.usage?.output_tokens || 0,
        totalTokens: (response.usage?.input_tokens || 0) + (response.usage?.output_tokens || 0),
      },
    }
  }

  async *getStream(
    options: AICompletionOptions & { message?: Anthropic.MessageCreateParams['messages'] },
  ): AsyncGenerator<AIStreamChunk> {
    const chatOptions: Anthropic.MessageCreateParams = {
      model: options.model,
      system: options.systemPrompt,
      messages: options.message ?? [{ role: 'user', content: options.userPrompt }],
      temperature: options.temperature ?? 0.7,
      max_tokens: options.maxTokens ?? 1024,
      tools: getToolsForPrompt(),
      stream: true,
    }

    const stream = await this.client.messages(chatOptions)
    const toolUseRequests: Array<{ id: string; name: string; input: unknown }> = []
    let currentBlock: {
      type: string
      content?: string
      id?: string
      name?: string
      json?: string
      input?: unknown
      shouldYield?: boolean
    } | null = null
    const messages: Anthropic.MessageParam[] = [...(options.message ?? [{ role: 'user', content: options.userPrompt }])]

    // First pass: collect text and tool use requests
    for await (const chunk of stream) {
      if (chunk.type === 'error') {
        yield chunk
        return
      }
      if (chunk.type === 'message_start') {
        // Message started, nothing to do
        continue
      } else if (chunk.type === 'content_block_start') {
        const block = chunk.content_block
        if (block.type === 'text') {
          currentBlock = { type: 'text', content: '' }
        } else if (block.type === 'tool_use') {
          currentBlock = {
            type: 'tool_use',
            id: block.id,
            name: block.name,
            json: '',
            input: block.input,
            shouldYield: SHOULD_YIELD_TOOLS.includes(block.name),
          }
        }
      } else if (chunk.type === 'content_block_delta') {
        if (!currentBlock) continue

        if (isTextContentDelta(chunk.delta)) {
          currentBlock.content = (currentBlock.content || '') + chunk.delta.text

          yield {
            type: 'text',
            content: chunk.delta.text,
          }
        } else if (isJsonInputDelta(chunk.delta)) {
          currentBlock.json = (currentBlock.json || '') + (chunk.delta.partial_json || '')
        }
      } else if (chunk.type === 'content_block_stop') {
        if (!currentBlock) continue

        if (currentBlock.type === 'tool_use' && currentBlock.name && currentBlock.input) {
          messages.push({
            role: 'assistant',
            content: [
              {
                type: 'tool_use',
                id: currentBlock.id!,
                name: currentBlock.name,
                input: currentBlock.input,
              },
            ],
          })
          if (currentBlock.shouldYield) {
            yield {
              type: 'json',
              content: currentBlock.json || '{}',
              parsed: maybeParseJson(currentBlock.json || '{}', options.schema),
            }
          } else {
            toolUseRequests.push({
              id: currentBlock.id!,
              name: currentBlock.name,
              input: JSON.parse(currentBlock.json || '{}'),
            })
          }
        }
        currentBlock = null
      } else if (chunk.type === 'message_stop') {
        // Store assistant's response in messages
        if (currentBlock?.type === 'text' || currentBlock?.shouldYield) {
          messages.push({
            role: 'assistant',
            content: currentBlock.content || currentBlock.json || '',
          })
        }

        // If we have tool requests, process them and continue the conversation
        if (toolUseRequests.length > 0) {
          // Execute all tool requests in parallel
          const toolResults = await Promise.all(
            toolUseRequests.map(async request => {
              const result = await invokeTool<unknown>(request.name, request.input)
              return {
                type: 'tool_result' as const,
                tool_use_id: request.id,
                content: result,
              }
            }),
          )

          // Add tool results to messages and start new stream
          messages.push({
            role: 'user',
            content: toolResults,
          })

          const toolResponse = await this.getStream({
            ...options,
            message: messages,
          })

          // Stream all chunks from the recursive call
          for await (const responseChunk of toolResponse) {
            yield responseChunk
          }
          return
        }

        // Close the stream when we have a complete response with no more tool use requests
        yield {
          type: 'text',
          content: '',
          done: true,
        }
      }
    }
  }

  async replyWithToolResult(
    options: AICompletionOptions,
    toolUseResponse: Anthropic.ToolUseBlock,
  ): Promise<AICompletionResponse> {
    const result = await invokeTool<unknown>(toolUseResponse.name, toolUseResponse.input)

    const toolResult: Anthropic.ToolResultBlockParam = {
      tool_use_id: toolUseResponse.id,
      type: 'tool_result',
      content: result,
    }

    return this.getCompletion({
      ...options,
      message: [{ role: 'user', content: [toolResult] }],
    })
  }
}
