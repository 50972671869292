//./plugins/posthog.js
import posthog from 'posthog-js'
import { App } from 'vue'

const POSTHOG_KEY = import.meta.env.VITE_POSTHOG_KEY
const POSTHOG_HOST = import.meta.env.VITE_POSTHOG_HOST
const VITE_STAGE = import.meta.env.VITE_STAGE || 'local'

export default {
  install(app: App) {
    if (VITE_STAGE !== 'local') {
      if (!POSTHOG_KEY || !POSTHOG_HOST) {
        console.warn('PostHog configuration is missing - POSTHOG_KEY and POSTHOG_HOST are required')
        return
      }
      app.config.globalProperties.$posthog = posthog.init(POSTHOG_KEY, {
        api_host: POSTHOG_HOST,
      })
    }
  },
}
