import { GrowthBook } from '@growthbook/growthbook'

export async function isForvia(growthBook?: GrowthBook | null) {
  return growthBook?.isOn('forvia-etl')
}

export async function getClients(growthBook?: GrowthBook | null) {
  return growthBook?.getFeatureValue('client-dropdown', [])
}

export async function sourcingEnabled(growthBook?: GrowthBook | null) {
  return growthBook?.isOn('sourcing')
}

export async function isAIenabledForUser(growthBook: GrowthBook | null, userId: string) {
  const enabledIds: { ids: string[] } | undefined = await growthBook?.getFeatureValue('ai-enabled-ids', { ids: [] })

  return enabledIds?.ids?.includes(userId)
}
