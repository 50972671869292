import { getAuth } from 'firebase/auth'

class BaseAIClientProxy {
  protected baseUrl = import.meta.env.VITE_AI_SERVER_URL

  private async getIdToken(): Promise<string> {
    const user = getAuth().currentUser
    if (!user) throw new Error('User not authenticated')
    return user.getIdToken()
  }

  protected async fetchWithAuth(input: RequestInfo | URL, init?: RequestInit): Promise<Response> {
    const idToken = await this.getIdToken()
    return fetch(input, {
      ...init,
      headers: {
        ...init?.headers,
        Authorization: `Bearer ${idToken}`,
      },
    })
  }
}

export class BaseAIClient<T, U, V> extends BaseAIClientProxy {
  url: string

  constructor({ url }: { url: string }) {
    super()
    this.url = url
  }

  async *messages(options: T): AsyncGenerator<U> {
    let reader: ReadableStreamDefaultReader | undefined

    try {
      const response = await this.fetchWithAuth(`${this.baseUrl}${this.url}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'text/event-stream',
        },
        body: JSON.stringify(options),
      })

      reader = response.body?.getReader()
      const decoder = new TextDecoder('utf-8')
      if (!reader) throw new Error('No reader available')

      while (true) {
        const { done, value } = await reader.read()
        if (done) break
        const chunk = decoder.decode(value)
        yield* chunk.split('\n\n').flatMap(line => {
          if (line.startsWith('data:')) {
            return JSON.parse(line.slice(5))
          }
          return []
        })
      }
    } catch (error) {
      const chunk = {
        type: 'error',
        content: error instanceof Error ? error.message : 'An unexpected error occurred.',
      }
      // @ts-expect-error - this is fine
      yield chunk
    } finally {
      if (reader && !reader.closed) reader.releaseLock()
    }
  }

  async getCompletion(options: T): Promise<V> {
    const response = await this.fetchWithAuth(`${this.baseUrl}${this.url}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(options),
    })
    return response.json()
  }
}
