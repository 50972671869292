<script setup lang="ts" generic="T extends Record<string, unknown>">
import { formatDistance } from 'date-fns'
import { getCurrentInstance } from 'vue'
import { getDateFnsLocaleFromBrowserLanguage } from '~/utils/formatter'

interface Column {
  key: string
  label: string
  type?: string
  align?: 'left' | 'center' | 'right'
  noWrap?: boolean
  vAlign?: 'top' | 'middle'
  maxWidth?: string | number
  minWidth?: string
  grow?: boolean
  sortable?: boolean
}

const emit = defineEmits<{
  rowClick: [row: T & { id: string }]
  rowSelect: [row: (T & { id: string }) | boolean]
  onSortChange: [key: string]
}>()

const props = withDefaults(
  defineProps<{
    columns: Column[]
    rows: (T & { id: string })[]
    title?: string
    emptyMessage?: string
    isLoading?: boolean
    isLoadingMore?: boolean
    testId?: string
    scrollable?: boolean
    sort?: { key: string; order: 'asc' | 'desc' | null }
  }>(),
  { scrollable: true },
)

const state = reactive<{ selectedRows: string[] }>({
  selectedRows: [],
})

const currentInstance = getCurrentInstance()?.vnode
const hasRowClick = currentInstance?.props?.['onRowClick']
const hasRowSelect = currentInstance?.props?.['onRowSelect']

interface ColumnTemplate {
  key: string
  template?: string
  width?: string
}

const getGridTemplateColumns = computed(() => {
  // Start with the row select column if present
  const allColumns: ColumnTemplate[] = hasRowSelect ? [{ key: 'select', width: '72px' }] : []

  // Add the regular columns
  allColumns.push(
    ...props.columns.map(column => {
      let template = column.minWidth ? `minmax(${column.minWidth}, auto)` : 'minmax(0, 1fr)'

      if (column.maxWidth) {
        // Handle numeric maxWidth values by converting to pixels
        const maxWidthValue = typeof column.maxWidth === 'number' ? `${column.maxWidth}px` : column.maxWidth
        template = `minmax(${column.minWidth || '0'}, ${maxWidthValue})`
      }

      if (column.grow) {
        template = `minmax(${column.minWidth || '0'}, ${column.grow === true ? '1fr' : column.grow})`
      }

      return { key: column.key, template }
    }),
  )

  // Generate the final template string
  return allColumns.map(col => col.template || col.width).join(' ')
})

function onRowClick(row: T & { id: string }) {
  emit('rowClick', row)
}

function toggleRowSelect(row: T & { id: string }) {
  const index = state.selectedRows.indexOf(row.id)
  if (index === -1) {
    state.selectedRows.push(row.id)
  } else {
    state.selectedRows.splice(index, 1)
  }
  emit('rowSelect', row)
}

function toggleSelectAll() {
  if (state.selectedRows.length < props.rows.length) {
    state.selectedRows = props.rows.map(row => row.id)
    emit('rowSelect', true)
  } else {
    state.selectedRows = []
    emit('rowSelect', false)
  }
}

function sortIfEnabled(column: Column) {
  if (column.sortable) {
    emit('onSortChange', column.key)
  }
}
</script>

<template>
  <div class="flex flex-col gap-4" :class="[props.scrollable !== false && 'h-full']">
    <CText v-if="props.title" size="l">
      {{ props.title }}
    </CText>

    <div class="flex flex-col flex-1 min-h-0 bg-white overflow-hidden rounded">
      <!-- Header -->
      <div
        class="grid border border-gray-200 sticky top-0 bg-white shadow-sm z-5 rounded-t w-full"
        :style="`grid-template-columns: ${getGridTemplateColumns}`"
      >
        <div v-if="hasRowSelect" class="p-4 flex items-center justify-center">
          <button class="text-gray-500" @click.stop.prevent="toggleSelectAll">
            <div i="carbon-checkbox" v-if="state.selectedRows.length === 0" />
            <div i="carbon-checkbox-checked" v-else-if="state.selectedRows.length === props.rows.length" />
            <div i="carbon-checkbox-indeterminate" v-else />
          </button>
        </div>
        <div
          v-for="column in props.columns"
          :key="column.key"
          class="group flex items-center gap-2 px-4 py-3 cursor-pointer"
          :class="[
            column.align === 'right' && 'text-right',
            column.align === 'center' && 'text-center',
            column.noWrap ? 'whitespace-nowrap' : 'break-words text-wrap whitespace-normal',
            column.sortable ? 'cursor-pointer' : '',
          ]"
          :style="{
            width: column.maxWidth,
            maxWidth: column.maxWidth,
          }"
          @click.stop.prevent="sortIfEnabled(column)"
        >
          <slot v-if="$slots[`header_${column.key}`]" :name="`header_${column.key}`" :item="column" />
          <CText size="s" class="line-clamp-1" muted v-else>{{ column.label }}</CText>
          <div
            v-if="column.sortable"
            class="text-gray-500 flex-shrink-0 group-hover:block"
            :class="{ hidden: !(sort?.key === column.key && sort?.order !== null) }"
            :data-test-id="`${testId}_sort_${column.key}`"
          >
            <div
              i="carbon-chevron-down"
              class="w-4 h-4 flex-shrink-0 transform"
              :class="{ 'rotate-180': sort?.order === 'asc' }"
            />
          </div>
        </div>
      </div>

      <!-- Body with floating scrollbar -->
      <div class="flex-1" :class="[props.scrollable !== false && 'min-h-0 relative']">
        <div
          :class="[
            props.scrollable !== false
              ? 'absolute inset-0 overflow-y-scroll overflow-x-hidden scrollbar-thin'
              : 'relative',
          ]"
        >
          <!-- Loading state -->
          <div v-if="props.isLoading" class="grid border-l border-r border-b rounded-b border-gray-200 p-8">
            <Loader />
          </div>

          <!-- Empty state -->
          <div
            v-else-if="rows.length === 0"
            class="grid border-l border-r border-b rounded-b border-gray-200 p-4 text-center"
            :data-test-id="`${testId}_empty_list`"
          >
            <CText size="s" muted>{{ props.emptyMessage || $t('global.no_data') }}</CText>
          </div>

          <!-- Rows -->
          <template v-else>
            <div
              v-for="(row, index) in props.rows"
              :key="row.id"
              @click="onRowClick(row)"
              class="grid border-l border-r border-b border-gray-200 hover:bg-gray-50 transition-colors w-full"
              :class="{
                'cursor-pointer': hasRowClick,
                'rounded-b': index === props.rows.length - 1,
              }"
              :style="`grid-template-columns: ${getGridTemplateColumns}`"
              :data-test-id="`${testId}-${index}`"
            >
              <div
                v-if="hasRowSelect"
                class="p-4 flex items-center justify-center"
                :class="{ 'rounded-bl': index === props.rows.length - 1 }"
              >
                <button
                  class="text-gray-500"
                  @click.stop.prevent="toggleRowSelect(row)"
                  :data-test-id="`${testId}_select_${index}`"
                >
                  <div i="carbon-checkbox-checked" v-if="state.selectedRows.includes(row.id)" />
                  <div i="carbon-checkbox" v-else />
                </button>
              </div>

              <div
                v-for="(column, colIndex) in props.columns"
                :key="column.key"
                class="p-4 flex items-center"
                :class="[
                  column.noWrap ? 'whitespace-nowrap' : 'break-words text-wrap whitespace-normal',
                  column.vAlign === 'top' ? 'items-start' : 'items-center',
                  column.align === 'right' && 'text-right justify-end',
                  column.align === 'center' && 'text-center justify-center',
                  index === props.rows.length - 1 && colIndex === props.columns.length - 1 && 'rounded-br',
                ]"
                :style="{
                  width: column.maxWidth,
                  maxWidth: column.maxWidth,
                  minHeight: '3rem',
                }"
              >
                <slot v-if="$slots[column.key]" :name="column.key" :item="row" :index="index" />
                <CText size="s" v-else-if="column.type === 'date'">{{
                  formatDistance(new Date(row[column.key] as string), new Date(), {
                    addSuffix: true,
                    locale: getDateFnsLocaleFromBrowserLanguage()!,
                  })
                }}</CText>
                <CText size="s" v-else class="!text-gray-600 line-clamp-2">{{ row[column.key] }}</CText>
              </div>
            </div>
          </template>

          <!-- Loading more state -->
          <div v-if="props.isLoadingMore" class="p-4">
            <Loader />
          </div>
          <!-- Observer slot -->
          <slot name="observer"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.scrollbar-thin {
  scrollbar-width: thin;
}

:deep(.absolute) {
  perspective: 1000px;
  backface-visibility: hidden;
  transform-style: preserve-3d;
}

:deep(.grid) {
  transform: translate3d(0, 0, 0);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:deep(.overflow-y-scroll) {
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}
</style>
