import Anthropic from '@anthropic-ai/sdk'
import {
  getBomContentDescription,
  GetBomContentInput,
  getBomContentName,
  getBomContentSchema,
  getBomContentShared,
} from '../../sharedTools/getBomContent'
import { AnthropicTool } from '../types'

const inputSchema: Anthropic.Tool['input_schema'] = getBomContentSchema

export const getBomContentTool: AnthropicTool<GetBomContentInput> = {
  name: getBomContentName,
  description: getBomContentDescription,
  inputSchema,
  func: async (input: GetBomContentInput) => {
    return getBomContentShared(input)
  },
}
