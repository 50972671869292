import OpenAI from 'openai'
import {
  getBomContentDescription,
  GetBomContentInput,
  getBomContentName,
  getBomContentSchema,
  getBomContentShared,
} from '../../sharedTools/getBomContent'

export const getBomContentTool: OpenAI.ChatCompletionTool = {
  type: 'function',
  function: {
    name: getBomContentName,
    description: getBomContentDescription,
    parameters: getBomContentSchema,
  },
}

export async function getBomContent(args: GetBomContentInput) {
  return getBomContentShared(args)
}
